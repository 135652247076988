const validator = {
  urlValidRule: (rule, value, cb) => {
    let reg = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/
    let reg2 = /^(?!.*http.*http).*/
    let reg3 = /^(?!.*(http:\/\/.*https:\/\/|https:\/\/.*http:\/\/)).*(http:\/\/|https:\/\/)?/

    if (typeof value === 'string') {
      if (!reg.test(value)) {
        cb(new Error(rule.message ? rule.message : '链接格式错误，请补充完整链接'))
      }
      if (!reg2.test(value)) {
        cb(new Error(rule.message ? rule.message : 'http://或https://不能超过一个'))
      }
      if (!reg3.test(value)) {
        cb(new Error(rule.message ? rule.message : 'http://或https://仅能有一个'))
      }
    } else {
      value.forEach((item) => {
        if (!reg.test(item)) {
          cb(new Error(rule.message ? rule.message : '链接格式错误，需带http或者https'))
        }
      })
    }
    return cb()
  },
  urlValidRuleWithoutScheme: (rule, value, cb) => {
    let reg =
      /^[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+(:[0-9]{1,5})?[-a-zA-Z0-9()@:%_\\\+\.~#?&//=]*$/

    if (typeof value === 'string') {
      if (!reg.test(value)) {
        cb(new Error(rule.message ? rule.message : '请输入正确的网站链接，格式：www.xxx.com'))
      }
    } else {
      value.forEach((item) => {
        if (!reg.test(item)) {
          cb(new Error(rule.message ? rule.message : '请输入正确的网站链接，格式：www.xxx.com'))
        }
      })
    }
    return cb()
  },
  validCharactor: (rule, value, cb) => {
    let realLength = 0,
      len = value.length,
      charCode = -1
    for (let i = 0; i < len; i++) {
      charCode = value.charCodeAt(i)
      if (charCode >= 0 && charCode <= 128) realLength += 1
      else realLength += 2
    }
    if (rule.max && realLength > rule.max) {
      cb(new Error(rule.message ? rule.message : `最长不能超过${rule.max}个字符`))
    }

    return cb()
  },
}

export default validator
