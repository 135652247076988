<template>
    <el-drawer v-model="isDrawerShow" :show-close="false" @close="$emit('close')" size="800px"
        custom-class="template-detail-drawer" :close-on-click-modal="false" ref="drawerRef">
        <template #title>
            <div class="top-container">
                <span>{{ props.name }}广告策略模板编辑</span>
                <img src="@/assets/images/adTemplate/close-icon.svg" alt="close" @click="isDrawerShow = false" />
            </div>
        </template>
        <div class="detail-container">
            <div class="detail-steps">
                <img src="@/assets/images/adTemplate/one-step.svg" alt="" v-if="currentStep === 0" />
                <img src="@/assets/images/adTemplate/one-step-success.svg" alt="" v-else />
                <span :class="{ 'active-step': currentStep === 0 }">模板信息</span>
                <div></div>
                <img src="@/assets/images/adTemplate/two-step.svg" alt="" v-if="currentStep === 0" />
                <img src="@/assets/images/adTemplate/two-step-success.svg" alt="" v-else />
                <span :class="{ 'active-step': currentStep === 1 }">创建预览</span>
            </div>
            <div class="detail-form">
                <el-form :rules="rules" ref="ruleFormRef" :model="ruleForm" v-show="currentStep === 0">
                    <div class="divider">账号授权</div>
                    <el-form-item label="授权平台:" required>
                        <el-button size="small" plain class="login-button" @click="openLoginDialog"
                            v-if="!ruleForm.fb_user_id">授权Facebook用户</el-button>
                        <div v-else class="login-user">
                            <img :src="ruleForm.fb_avatar" alt="avatar" />
                            <span>{{ ruleForm.fb_name }}</span>
                            <span class="help" @click="openLoginDialog" style="margin-left: 0">切换Facebook授权</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="广告账户:" v-if="ruleForm.fb_user_id">
                        <el-button size="small" type="primary" class="unlogin-button"
                            @click="changeAccount">选择广告账户</el-button>
                    </el-form-item>
                    <el-form-item label="已选账户:"
                        v-if="ruleForm.fb_user_id && ruleForm.account_info.length > 0 && ruleForm.account_info[0].account_id"
                        style="margin-bottom: 0"></el-form-item>
                    <el-table :data="ruleForm.account_info"
                        v-if="ruleForm.fb_user_id && ruleForm.account_info.length > 0 && ruleForm.account_info[0].account_id">
                        <el-table-column prop="name" label="账户名称" />
                        <el-table-column prop="account_id" label="账户ID" />
                        <el-table-column label="公共主页">
                            <template #default="{ row }">{{ row.pageOptions.filter(item => item.id === row.page)[0].name
                                }}</template>
                        </el-table-column>
                        <el-table-column prop="pixel" label="像素Pixel">
                            <template #default="{ row }">{{ row.pixelOptions.filter(item => item.id ===
                                row.pixel)[0].name
                                }}</template>
                        </el-table-column>
                    </el-table>
                    <div class="divider">{{ ruleForm.type === 1 || ruleForm.type === 3 ? '广告系列' : '进阶赋能型智能购物广告系列' }}
                        <span v-show="ruleForm.type === 2 && audienceSavable" @click="resetAudience">恢复默认</span>
                    </div>
                    <el-form-item label="广告系列名称:" prop="campaign_name" style="margin-bottom: 8px;">
                        <el-input size="small" clearable v-model="ruleForm.campaign_name"
                            v-if="ruleForm.type === 1 || ruleForm.type === 3" :validate-event="false" />
                        <el-input size="small" clearable v-model="ruleForm.campaign_name" v-else
                            @input="ruleForm.adsets_name = ruleForm.campaign_name" :validate-event="false" />
                    </el-form-item>
                    <el-form-item label="广告目标:" style="margin-bottom: 0px;">
                        <span>{{ ruleForm.smart_promotion_type }}</span>
                    </el-form-item>
                    <el-form-item label="赋能型广告预算优化:" v-if="ruleForm.type === 1 || ruleForm.type === 3"
                        style="margin-bottom: 0px;">
                        <span>{{ ruleForm.ad_budget_improve }}</span>
                    </el-form-item>
                    <el-form-item label="自动规则:" style="margin-bottom: 0;">
                        <vxe-switch v-model="ruleForm.rule_switch" size="mini" open-label="打开" close-label="关闭"
                            @change="clearRule"></vxe-switch>
                    </el-form-item>
                    <div v-if="ruleForm.rule_switch">
                        <!-- <el-form-item label="应用层级:" style="margin-bottom: 0;"> -->
                        <!-- <el-radio-group v-model="ruleForm.rule_level"> -->
                        <!-- <el-radio :label="1">广告系列</el-radio> -->
                        <!-- <el-radio :label="2">广告组</el-radio> -->
                        <!-- <el-radio :label="3">广告</el-radio> -->
                        <!-- </el-radio-group> -->
                        <!-- </el-form-item> -->
                        <el-form-item label="选择规则:">
                            <el-select v-model="ruleForm.rule" placeholder="请选择自动化规则" size="small" style="width: 100%;"
                                filterable popper-class="ad-template-rule-select">
                                <div class="add-rule" @click="goCustomRule()">
                                    <i class="iconfont icon-xinjian" />
                                    <span>新建规则</span>
                                </div>
                                <el-option v-for="item in options.rule" :key="item.id" :label="item.name"
                                    :value="item.id">
                                    <el-row type="flex" justify="space-between" align="middle">
                                        <span>{{ item.name }}</span>
                                        <el-tooltip effect="dark" content="去编辑" placement="top">
                                            <i class="iconfont icon-qubianji" @click="goCustomRule(item.id)"
                                                v-show="item.name !== '成本控制策略'" />
                                        </el-tooltip>
                                    </el-row>
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="规则描述:" class="rule-form-item">
                            <div class="rule-description">
                                <span class="tip" v-if="!ruleForm.rule">选择自动规则后，展示规则详情说明</span>
                                <div v-else>
                                    <div class="rule-description-item">
                                        <span style="width: 100%;">
                                            <div class="name">
                                                <span>
                                                    {{ options.rule.length > 0 && options.rule.filter(i => i.id ===
                                                        ruleForm.rule)[0].name +
                                                        "：" }}
                                                </span>
                                                <i class="iconfont icon-qubianji" @click="goCustomRule(ruleForm.rule)"
                                                    v-show="options.rule.length > 0 && options.rule.filter(i => i.id === ruleForm.rule)[0].name !== '成本控制策略'" />
                                            </div>
                                            <div v-html="options.rule.length > 0 && options.rule.filter(i => i.id ===
                                                ruleForm.rule)[0].description" class="content"></div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="divider" v-if="ruleForm.type === 1 || ruleForm.type === 3" style="margin-top: 16px;">
                        广告组
                        <span @click="resetAudience" v-show="audienceSavable">恢复默认</span>
                    </div>
                    <el-form-item label="广告组名称:" prop="adsets_name" v-show="ruleForm.type === 1 || ruleForm.type === 3"
                        style="margin-bottom: 8px;">
                        <el-input size="small" clearable v-model="ruleForm.adsets_name" :validate-event="false" />
                    </el-form-item>
                    <el-form-item label="转化发生位置:" style="margin-bottom: 0px;">
                        <span>{{ ruleForm.optimization_goal }}</span>
                    </el-form-item>
                    <el-form-item label="成效目标:" style="margin-bottom: 0px;">
                        <span>{{ ruleForm.bid_strategy }}</span>
                    </el-form-item>
                    <el-form-item label="转化事件:" style="margin-bottom: 8px;">
                        <span>{{ ruleForm.custom_event_type }}</span>
                    </el-form-item>
                    <el-form-item label="单日预算:" prop="daily_budget" style="margin-bottom: 12px;">
                        <el-input size="small" clearable v-model="ruleForm.daily_budget"
                            style="width: 250px;margin-top: 4px;">
                            <template #append>$</template>
                        </el-input>
                        <span class="recommend"
                            v-show="ruleForm.daily_budget && (ruleForm.daily_budget < ruleForm.recommend_daily_budget)">推荐使用{{
                                ruleForm.recommend_daily_budget }}美元以上的预算，提高广告成功率</span>
                    </el-form-item>
                    <el-form-item label="投放开始时间:" style="margin-bottom: 8px;">
                        <span>{{ ruleForm.start_time }}</span>
                    </el-form-item>
                    <el-form-item label="性别:" v-if="ruleForm.type === 1" style="margin-bottom: 12px;">
                        <el-radio-group v-model="ruleForm.sex" size="small" text-color="#2B82FF" fill="#2B82FF">
                            <el-radio-button :label="0">全部</el-radio-button>
                            <el-radio-button :label="1">男</el-radio-button>
                            <el-radio-button :label="2">女</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="年龄:" v-if="ruleForm.type === 1" style="margin-bottom: 12px;">
                        <el-select v-model="ruleForm.age_min" placeholder="年龄" size="small" style="width: 90px;">
                            <el-option v-for="item in options.age_min" :key="item" :label="item" :value="item" />
                        </el-select>
                        <span class="age-divider" />
                        <el-select v-model="ruleForm.age_max" placeholder="年龄" size="small" style="width: 90px;">
                            <el-option v-for="item in options.age_max" :key="item" :label="item" :value="item" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="地区:" prop="region">
                        <el-select v-model="ruleForm.region" multiple placeholder="请选择地区" size="small"
                            style="width: 100%;" filterable>
                            <el-option v-for="item in options.region" :key="item.id" :label="item.name"
                                :value="item.id" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="受众兴趣词:" v-if="ruleForm.type === 1" prop="audience_interest_words"
                        style="margin-bottom: 24px;">
                        <el-select v-model="ruleForm.audience_interest_words" multiple placeholder="请选择受众兴趣词"
                            size="small" style="width: 100%;" filterable>
                            <el-option v-for="item in options.audience_interest_words" :key="item.id" :label="item.name"
                                :value="item.id" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="受众兴趣词:" v-if="ruleForm.type === 3" style="margin-bottom: 24px;">
                        <span>使用进阶赋能型受众，自动为你寻找最适合的广告受众</span>
                    </el-form-item>
                    <div class="divider">广告</div>
                    <el-form-item label="标题:" prop="title" style="margin-bottom: 8px;">
                        <el-input size="small" clearable v-model="ruleForm.title" placeholder="请输入标题"
                            :validate-event="false" />
                    </el-form-item>
                    <el-form-item label="描述:" style="margin-bottom: 8px;">
                        <el-input size="small" clearable v-model="ruleForm.description" placeholder="添加其它详情" />
                    </el-form-item>
                    <el-form-item label="正文:" prop="message" style="margin-bottom: 8px;">
                        <el-input size="small" clearable v-model="ruleForm.message" placeholder="介绍广告内容"
                            :validate-event="false" />
                    </el-form-item>
                    <el-form-item required style="margin-bottom: 24px;">
                        <template #label>
                            <span>行动号召</span>
                            <el-tooltip class="item" effect="light" content="" placement="top">
                                <template #content>
                                    <span>在广告中展示按钮或链接，吸引受众采取预期操作。</span>
                                </template>
                                <i class="el-icon-warning-outline"></i>
                            </el-tooltip>
                        </template>
                        <el-select v-model="ruleForm.call_type" placeholder="请选择行动号召" size="small" style="width: 100%;">
                            <el-option v-for="item in options.call_type" :key="item.id" :label="item.name"
                                :value="item.id" />
                        </el-select>
                    </el-form-item>
                    <div class="divider">素材</div>
                    <el-form-item label="批量类型:" style="margin-bottom: 0;">
                        <el-radio-group v-model="ruleForm.batch_type">
                            <el-radio :label="1">1-1-N</el-radio>
                            <el-radio :label="2">1-N-1</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item required style="margin-bottom: 12px;">
                        <template #label>
                            <span>素材</span>
                            <el-tooltip class="item" effect="light" content="" placement="top">
                                <template #content>
                                    <div>图片：最小尺寸: 600 * 600像素，最大文件大小: 30MB</div>
                                    <div>视频：推荐宽高比: 最小宽度为600divx，文件大小: 不超过50M</div>
                                </template>
                                <i class="el-icon-warning-outline"></i>
                            </el-tooltip>
                        </template>
                        <el-row type="flex" align="middle">
                            <el-upload :action="uploadUrl" :show-file-list="false" :before-upload="beforeUpload"
                                :on-success="uploadSuccess" multiple>
                                <el-button size="small" plain class="add-material-button"
                                    icon="iconfont icon-jiahao">添加素材</el-button>
                            </el-upload>
                            <vxe-checkbox v-model="ruleForm.link_switch" size="medium"
                                style=" margin-left: 64px;">使用固定推广链接</vxe-checkbox>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="推广链接:" prop="link" v-if="ruleForm.link_switch">
                        <el-input size="small" clearable v-model="ruleForm.link" placeholder="请输入推广链接"
                            @paste.native="handleFixedPaste" :validate-event="false" />
                    </el-form-item>
                    <div class="material-container" v-show="ruleForm.material.length > 0">
                        <div v-for="(item, index) in ruleForm.material" :key="item.uid">
                            <div class="material" v-loading="item.loading" element-loading-text="上传中，请耐心等待...">
                                <i class="type-icon iconfont icon-tupian" v-if="item.type === 'image'"></i>
                                <i class="type-icon iconfont icon-shipin" v-else></i>
                                <img :src="item.path1" alt="" v-if="item.type === 'image'" />
                                <video :src="item.path1" alt="" v-else />
                                <div class="mask">
                                    <i class="iconfont icon-shanchu" @click="ruleForm.material.splice(index, 1)"></i>
                                    <el-button size="small" type="primary" @click="handlePreview(item)">预览素材</el-button>
                                    <el-upload :action="uploadUrl" :show-file-list="false"
                                        :before-upload="e => beforeUploadChange(index, e)" :on-success="uploadSuccess">
                                        <el-button size="small" type="primary">更换素材</el-button>
                                    </el-upload>
                                </div>
                            </div>
                            <el-form-item :prop="`material[${index}].link`" :rules="[
                                { required: true, message: '请输入带http或https的链接' },
                                { validator: validator.urlValidRule },
                            ]" v-if="!ruleForm.link_switch" style="margin-bottom: 0;">
                                <el-input size="small" clearable v-model="item.link" placeholder="请输入推广链接"
                                    @paste.native="e => handlePaste(e, index)" :validate-event="false" />
                            </el-form-item>
                        </div>
                    </div>
                </el-form>
                <!-- 广告预览 -->
                <div v-show="currentStep === 1" style="padding-bottom: 40px;">
                    <div class="divider" style="margin-bottom: 12px;">投放预览</div>
                    <div class="preview-container">
                        <el-form :rules="rules" ref="previewRuleFormRef" :model="ruleForm">
                            <el-form-item label="广告投放账号:">
                                <div class="account-id">
                                    <span v-for="item in ruleForm.account_info" :key="item.account_id">{{
                                        item.account_id }}</span>
                                </div>
                            </el-form-item>
                            <el-form-item label="广告系列名称:">
                                {{ ruleForm.campaign_name }}
                            </el-form-item>
                            <el-form-item label="创建广告系列次数:" prop="batch_num">
                                <el-input v-model="ruleForm.batch_num" style="width: 64px;margin-right: 6px;"
                                    size="small" :validate-event="false" />次
                            </el-form-item>
                            <div class="preview-table">
                                <div class="header">
                                    <div>广告组名称</div>
                                    <div>受众人群</div>
                                    <div>广告组日预算</div>
                                </div>
                                <div class="content">
                                    <div class="content-tree">
                                        <el-tree :data="previewData" :props="{
                                            children: 'children',
                                            label: 'label',
                                        }">
                                            <template #default="{ node, data }">
                                                <div v-if="data.path && node.level === 2">
                                                    <img :src="data.path"
                                                        style="width: 28px; height: 28px; margin-right: 4px"
                                                        v-if="data.type === 'image'" />
                                                    <video :src="data.path"
                                                        style="width: 28px; height: 28px; margin-right: 4px"
                                                        v-else></video>
                                                </div>
                                                <span>{{ node.label }}</span>
                                            </template>
                                        </el-tree>
                                    </div>
                                    <div>
                                        <span>{{ audience }}</span>
                                    </div>
                                    <div>
                                        <span> $ {{ ruleForm.daily_budget }} </span>
                                    </div>
                                </div>
                                <div class="footer">
                                    <div>共生成{{ ruleForm.material.length }}条广告</div>
                                    <div></div>
                                    <div v-show="ruleForm.batch_type === 1">每日总预算：${{ ruleForm.daily_budget *
                                        ruleForm.account_info.length }}</div>
                                    <div v-show="ruleForm.batch_type === 2">每日总预算：${{ ruleForm.daily_budget *
                                        ruleForm.material.length * ruleForm.account_info.length }}</div>
                                </div>
                            </div>
                            <el-form-item label="应用自动规则:" v-if="ruleForm.rule_switch && ruleForm.rule"
                                style="margin-top: 24px;">
                                <div class="rule-description">
                                    <div class="rule-description-item">
                                        <span style="width: 100%;">
                                            <span>
                                                {{ options.rule.length > 0 && options.rule.filter(i => i.id ===
                                                    ruleForm.rule)[0].name + "：" }}
                                            </span>
                                            <div v-html="options.rule.length > 0 && options.rule.filter(i => i.id ===
                                                ruleForm.rule)[0].description" class="content"></div>
                                        </span>
                                    </div>
                                </div>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="divider">创建提示</div>
                    <span class="create-tip">广告模板创建成功后即开始投放，如果您有需要自定义的修改内容，可前往Meta广告管理后台进行修改。</span>
                </div>
            </div>
            <div class="detail-footer">
                <el-button @click="isSaveTemplateDialogShow = true" size="small" type="primary"
                    v-show="audienceSavable">保存为我的模板</el-button>
                <el-button @click="isDrawerShow = false" size="small" v-if="currentStep === 0">取消</el-button>
                <el-button @click="currentStep--" size="small" type="primary" v-else>上一步</el-button>
                <el-button @click="handleNext" size="small" v-if="currentStep === 0">下一步</el-button>
                <el-button @click="handleCreate" size="small" type="primary" v-else>创建广告</el-button>
            </div>
        </div>
    </el-drawer>

    <!-- FB授权弹窗 -->
    <LoginDialog ref="loginDialogRef" v-if="isDialogShow" @close="isDialogShow = false; isDrawerShow = false"
        @submit="handleFBAuth" />

    <!-- 我的模板保存弹窗 -->
    <SaveTemplateDialog v-if="isSaveTemplateDialogShow" @close="isSaveTemplateDialogShow = false"
        @save="$emit('save')" />

    <!-- 素材图片预览 -->
    <el-image-viewer v-if="showImgPreview" :zoom-rate="0.5" @close="showImgPreview = false; materialPreviewList = [];"
        :url-list="materialPreviewList" style="z-index: 9999;" />

    <!-- 素材视频预览 -->
    <el-dialog v-model="showVideoPreview" width="40%" custom-class="template-detail-video-preview"
        @close="showVideoPreview = false; materialPreviewList = []">
        <video :src="materialPreviewList[0]" controls :show-close="false"></video>
    </el-dialog>

    <!-- 自定义规则弹窗 -->
    <RuleDrawer ref="ruleDrawerRef" v-if="isRuleDrawerShow" @submit="handleRuleChange"
        @close="isRuleDrawerShow = false" />

</template>

<script setup>
import LoginDialog from './LoginDialog.vue'
import SaveTemplateDialog from './SaveTemplateDialog.vue'
import RuleDrawer from '@/views/ads/adRule/RuleDrawer.vue'
import { ref, reactive, onMounted, nextTick, watch } from 'vue'
import base from '@/request/base'
import api from '@/request/api/ad'
import { ElMessage } from 'element-plus'
import validator from '@/base/validator'
import filters from '@/base/filters/base'

const props = defineProps({
    id: { type: Number, default: 1 },
    name: { type: String, default: '' },
    audience: { type: String, default: '' },
})

const emit = defineEmits(['submit', 'close', 'save'])

const loginDialogRef = ref(null)
const drawerRef = ref(null)
const ruleDrawerRef = ref(null)
const isDrawerShow = ref(true)
const isRuleDrawerShow = ref(false) // 是否显示自定义规则弹窗
const isDialogShow = ref(false) // 是否显示授权弹窗
const isSaveTemplateDialogShow = ref(false) // 是否显示保存我的模板弹窗
const currentStep = ref(0)
const uploadUrl = ref(`${base.admin_url}/cloud/file/uploadfile_creativity`)
const showImgPreview = ref(false)
const showVideoPreview = ref(false)
const materialPreviewList = ref([])
const ruleFormRef = ref(null)
const previewRuleFormRef = ref(null)
const ruleForm = ref({
    id: props.id, // 模板id
    type: 1, // 模板类型 1: FB正常 2: ASC模板
    c_id: '', // 模板分类id
    account_info: [], // 选择的多个广告账号id、公共主页、像素
    fb_user_id: '', // 授权FB用户id
    fb_avatar: '', // 授权FB头像
    fb_name: '', // 授权FB名称
    campaign_name: '', // 广告系列名称
    smart_promotion_type: '', // 广告目标
    ad_budget_improve: '关闭', // 赋能型广告预算优化
    rule_switch: false, // 自动规则开关
    rule: '', // 自动化规则
    adsets_name: '', // 广告组名称
    optimization_goal: '', // 转化发生位置
    bid_strategy: '', // 成效目标
    custom_event_type: '', // 转化事件
    daily_budget: null, // 单日预算
    recommend_daily_budget: 1, // 推荐最小预算
    start_time: '模板创建时间的第二天凌晨0点', // 投放开始时间
    region: '', // 地区
    sex: 0, // 性别
    age_min: '', // 最小年龄
    age_max: '', // 最大年龄
    audience_interest_words: '', // 受众兴趣词
    title: '', // 标题
    description: '', // 描述
    message: '', // 正文
    call_type: 'SHOP_NOW', // 行动号召
    batch_type: 1, // 批量类型
    link_switch: true, // 固定推广链接开关
    link: 'https://', // 固定推广链接
    material: [], // 广告素材
    batch_num: 1, // 创建广告次数
})
const options = ref({
    page: [],
    pixel: [],
    rule: [],
    region: [],
    age_max: [],
    age_min: [],
    audience_interest_words: [],
    call_type: [],
})
const validateBudget = (rule, value, callback) => {
    if (parseFloat(value) < 1) {
        callback(new Error('预算金额最小1美金'))
    } else {
        callback()
    }
}
// 表单验证规则
const rules = reactive({
    page_id: [{ required: true, message: '请选择公共主页', trigger: 'change' }],
    pixel_id: [{ required: true, message: '请选择像素', trigger: 'change' }],
    daily_budget: [
        { required: true, message: '请输入单日预算', trigger: 'change' },
        { pattern: /^[0-9]+$/, message: '预算金额必须为数字', trigger: 'change' },
        { validator: validateBudget, trigger: 'change' },
    ],
    campaign_name: [{ required: true, message: '请输入广告系列名称' }],
    adsets_name: [{ required: true, message: '请输入广告组名称' }],
    region: [{ required: true, message: '请选择地区', trigger: 'change' }],
    audience_interest_words: [{ required: true, message: '请选择受众兴趣词', trigger: 'change' }],
    title: [{ required: true, message: '请输入标题' }],
    message: [{ required: true, message: '请输入广告正文内容' }],
    link: [
        { required: true, message: '请输入带http或者https推广链接' },
        { validator: validator.urlValidRule },
    ],
    batch_num: [
        { required: true, message: '请输入创建广告次数' },
        { pattern: /^[1-9]+$/, message: '创建广告次数必须为大于0的数字' },
    ],
})
const previewData = ref([])
const defaultAudience = ref({}) // 默认受众
const audienceSavable = ref(false) // 是否可保存我的模板

/** 检查用户授权 */
const checkAuth = () => {
    return new Promise((resolve, reject) => {
        if (localStorage.getItem('CurrentFBAuthUser')) {
            const user = JSON.parse(localStorage.getItem('CurrentFBAuthUser'))
            api.template.getFBAuthExpire(user.fb_user_id).then((res) => {
                if (res.code === 200) {
                    ruleForm.value.fb_user_id = user.fb_user_id
                    ruleForm.value.fb_avatar = user.fb_avatar
                    ruleForm.value.fb_name = user.fb_name
                    if (localStorage.getItem('BatchAdTemplateAccountInfo')) ruleForm.value.account_info = JSON.parse(localStorage.getItem('BatchAdTemplateAccountInfo'))
                }
                resolve()
            }).catch((error) => {
                resolve()
            })
        } else {
            resolve()
        }
    })
}

/** 检查当前模板是否有草稿 */
const checkDetailSaved = () => {
    nextTick(() => {
        if (localStorage.getItem('BatchAdTemplateDetail')) {
            const detail = JSON.parse(localStorage.getItem('BatchAdTemplateDetail'))
            if (ruleForm.value.id !== detail.id) return
            ruleForm.value.account_info = detail.account_info
            ruleForm.value.campaign_name = detail.campaign_name
            ruleForm.value.rule_switch = detail.rule_switch
            ruleForm.value.rule = detail.rule
            ruleForm.value.adsets_name = detail.adsets_name
            ruleForm.value.daily_budget = detail.daily_budget
            ruleForm.value.sex = detail.sex
            ruleForm.value.age_min = detail.age_min
            ruleForm.value.age_max = detail.age_max
            ruleForm.value.region = detail.region
            ruleForm.value.audience_interest_words = detail.audience_interest_words
            ruleForm.value.title = detail.title
            ruleForm.value.description = detail.description
            ruleForm.value.message = detail.message
            ruleForm.value.call_type = detail.call_type
            ruleForm.value.batch_type = detail.batch_type
            ruleForm.value.link_switch = detail.link_switch
            ruleForm.value.link = detail.link
            ruleForm.value.material = detail.material
        }
    })
}

/** 本地保存表单和受众 */
const handleSaveDetail = () => {
    localStorage.setItem('BatchAdTemplateDetail', JSON.stringify(ruleForm.value))
    localStorage.setItem('BatchAdTemplateCustomAudience', JSON.stringify(ruleForm.value))
    localStorage.setItem('AdTemplateAudience', props.audience)
    // 判断是否可保存我的模板
    if (ruleForm.value.sex !== defaultAudience.value.sex ||
        ruleForm.value.age_min !== defaultAudience.value.age_min ||
        ruleForm.value.age_max !== defaultAudience.value.age_max ||
        JSON.stringify(ruleForm.value.region) !== JSON.stringify(defaultAudience.value.region) ||
        JSON.stringify(ruleForm.value.audience_interest_words) != JSON.stringify(defaultAudience.value.audience_interest_words)) {
        audienceSavable.value = true
    } else {
        audienceSavable.value = false
    }
}

/** 打开授权弹窗 */
const openLoginDialog = () => {
    isDialogShow.value = true
    drawerRef.value.$el.nextElementSibling.style.display = 'none'
}

/** 选择广告账户 */
const changeAccount = () => {
    openLoginDialog()
    nextTick(() => {
        loginDialogRef.value.changeAccount(ruleForm.value.account_info)
    })
}

/** 清空规则 */
const clearRule = () => {
    if (!ruleForm.value.rule_switch) {
        ruleForm.value.rule = ''
    }
}

/** 跳转新建规则和编辑规则 */
const goCustomRule = async (id) => {
    if (!ruleForm.value.fb_user_id) {
        ElMessage.error('请先授权Facebook用户')
        return
    }
    isRuleDrawerShow.value = true
    await nextTick()
    if (id) {
        ruleDrawerRef.value.editRule(id)
    } else {
        ruleDrawerRef.value.addRule()
    }
}

/** 规则提交的回调 */
const handleRuleChange = (id) => {
    ruleForm.value.rule = id
    isRuleDrawerShow.value = false
    getAllAudience()
}

/** 图片素材格式校验 */
const imgCheck = async (file) => {
    const isLt30M = file.size / 1024 / 1024 < 30
    if (!isLt30M) {
        ElMessage.error(`${file.name}大小不符合，不能超过 30MB!`)
    }
    const isLtSize = await new Promise(function (resolve, reject) {
        const img = new Image()
        const _URL = window.URL || window.webkitURL
        img.onload = function () {
            const valid = img.width >= 600 && img.height >= 600
            valid ? resolve(true) : reject(false)
        }
        img.src = _URL.createObjectURL(file)
    }).then(
        () => {
            return true
        },
        () => {
            ElMessage.error(`${file.name}尺寸不合符，最小尺寸: 600 * 600像素`)
            return Promise.reject(false)
        }
    )

    return isLt30M && isLtSize
}

/** 视频素材格式校验 */
const videoCheck = async (file) => {
    // const isLtType = file.type === 'video/mp4' || file.type === 'video/mov' || file.type === 'image/gif'
    // if (!isLtType) {
    //     ElMessage.error('上传视频格式只能为 .mp4、.mov 或 .gif!')
    // }
    const isLt50M = file.size / 1024 / 1024 < 50
    if (!isLt50M) {
        ElMessage.error(`${file.name}，不能超过 50MB!`)
    }
    const isLtSize = await new Promise(function (resolve, reject) {
        const _URL = window.URL || window.webkitURL
        const audio = document.createElement('video')
        audio.addEventListener('loadedmetadata', function (_event) {
            const valid = audio.videoWidth >= 600 && audio.videoHeight >= 600
            valid ? resolve(true) : reject(false)
        })
        audio.src = _URL.createObjectURL(file)
    }).then(
        () => {
            return true
        },
        () => {
            ElMessage.error(`${file.name}尺寸不符合，上传素材视频宽度、高度不能小于600px!`)
            return Promise.reject(false)
        }
    )
    return isLt50M && isLtSize
}

/** 素材上传前置钩子 */
const beforeUpload = async (file) => {
    const type = file.type.split('/')[0]
    let res = false
    switch (type) {
        case 'image':
            res = await imgCheck(file)
            if (res) {
                ruleForm.value.material.push({
                    id: file.uid,
                    path: '', // 用来上传
                    path1: '', // 用来展示
                    link: 'https://',
                    type: 'image',
                    loading: true,
                })
            }
            return res
        case 'video':
            res = await videoCheck(file)
            if (res) {
                ruleForm.value.material.push({
                    id: file.uid,
                    path: '', // 用来上传
                    path1: '', // 用来展示
                    link: 'https://',
                    type: 'video',
                    loading: true,
                })
            }
            return res
    }
}

/** 素材上传完成 */
const uploadSuccess = (response, uploadFile) => {
    console.log('上传完成', response, uploadFile)
    if (response.code === '200') {
        ruleForm.value.material.forEach((item, index) => {
            if (item.id === uploadFile.uid) {
                ruleForm.value.material[index].loading = false
                ruleForm.value.material[index].path = response.path
                ruleForm.value.material[index].path1 = response.path1
            }
        })
    } else {
        ElMessage.error(response.msg)
        ruleForm.value.material.forEach((item, index) => {
            if (item.id === uploadFile.uid) {
                ruleForm.value.material.splice(index, 1)
            }
        })
    }
}

/** 更换素材前置钩子 */
const beforeUploadChange = async (index, file) => {
    const type = file.type.split('/')[0]
    let res = false
    switch (type) {
        case 'image':
            res = await imgCheck(file)
            if (res) {
                ruleForm.value.material[index].id = file.uid
                ruleForm.value.material[index].path = ''
                ruleForm.value.material[index].path1 = ''
                ruleForm.value.material[index].type = 'image'
                ruleForm.value.material[index].loading = true
            }
            return res
        case 'video':
            res = await videoCheck(file)
            if (res) {
                ruleForm.value.material[index].id = file.uid
                ruleForm.value.material[index].path = ''
                ruleForm.value.material[index].path1 = ''
                ruleForm.value.material[index].type = 'video'
                ruleForm.value.material[index].loading = true
            }
            return res
    }
}

/** 素材预览 */
const handlePreview = (item) => {
    if (item.type === 'image') {
        materialPreviewList.value = [item.path1]
        showImgPreview.value = true
    } else {
        materialPreviewList.value = [item.path1]
        showVideoPreview.value = true
    }
}

/** 获取广告模板详情 */
const getTemplateDetail = async () => {
    await api.template.getTemplateDetail(props.id).then((res) => {
        ruleForm.value.type = res.data.type // 1: FB正常 2: ASC模板
        ruleForm.value.c_id = res.data.c_id
        ruleForm.value.smart_promotion_type = res.data.smart_promotion_type
        ruleForm.value.optimization_goal = res.data.optimization_goal
        ruleForm.value.bid_strategy = res.data.bid_strategy
        ruleForm.value.custom_event_type = res.data.custom_event_type
        ruleForm.value.sex = res.data.sex
        ruleForm.value.region = res.data.region
        ruleForm.value.age_min = res.data.age_min
        ruleForm.value.age_max = res.data.age_max
        ruleForm.value.audience_interest_words = res.data.audience_interest_words
        ruleForm.value.recommend_daily_budget = res.data.daily_budget
        handleDefaultName()
        // 如果用户已经授权并选择账户并且没有过期（赋值我的模板中存的授权信息）
        if (ruleForm.value.fb_user_id && res.data.c_id === 6) {
            ruleForm.value.account_info = res.data.account_info
        }
        defaultAudience.value = {
            sex: res.data.sex,
            age_min: res.data.age_min,
            age_max: res.data.age_max,
            region: res.data.region,
            audience_interest_words: res.data.audience_interest_words,
        }
    })
    checkDetailSaved()
}

/** 获取广告模板所有受众选项 */
const getAllAudience = () => {
    api.template.getAllAudience(props.id).then((res) => {
        options.value.rule = res.rule
        options.value.region = res.adv_area
        options.value.audience_interest_words = res.adv_interests
        options.value.age_min = res.age_min
        options.value.age_max = res.age_max
        options.value.call_type = res.call_type
    })
}

/** 生成广告系列、广告组、广告名称默认值 */
const handleDefaultName = () => {
    const date = filters.formatDate('YYYYMMdd')
    ruleForm.value.campaign_name = `${date}-${props.name}-${ruleForm.value.bid_strategy}`
    ruleForm.value.adsets_name = `${date}-${props.name}-${ruleForm.value.region.join('/')}`
    ruleForm.value.title = `${date}-${props.name}`
}

/** 恢复默认受众 */
const resetAudience = () => {
    ruleForm.value.sex = defaultAudience.value.sex
    ruleForm.value.age_min = defaultAudience.value.age_min
    ruleForm.value.age_max = defaultAudience.value.age_max
    ruleForm.value.region = defaultAudience.value.region
    ruleForm.value.audience_interest_words = defaultAudience.value.audience_interest_words
}

/** 更改FB授权状态 */
const handleFBAuth = (user) => {
    ruleForm.value.account_info = JSON.parse(localStorage.getItem('BatchAdTemplateAccountInfo'))
    ruleForm.value.fb_user_id = user.fb_user_id
    ruleForm.value.fb_avatar = user.fb_avatar
    ruleForm.value.fb_name = user.fb_name
    drawerRef.value.$el.nextElementSibling.style.display = 'block'
    isDialogShow.value = false
}

/** 固定推广链接处理逻辑 */
const handleFixedPaste = (e) => {
    // 阻止默认粘贴行为（可选，取决于你是否需要立即显示原始数据）
    e.preventDefault()
    // 获取剪贴板中的文本数据
    const text = (e.originalEvent || e).clipboardData.getData('text/plain').trim()
    // 如果检测text有自带http://或者https://，就用text的，如果没有，就用我们默认的https://
    const replacedText = text.includes('http://') || text.includes('https://') ? text : 'https://' + text
    // 将转换后的数据赋值给 inputValue
    ruleForm.value.link = replacedText
}

/** 推广链接处理逻辑 */
const handlePaste = (e, index) => {
    // 阻止默认粘贴行为（可选，取决于你是否需要立即显示原始数据）
    e.preventDefault()
    // 获取剪贴板中的文本数据
    const text = (e.originalEvent || e).clipboardData.getData('text/plain').trim()
    // 如果检测text有自带http://或者https://，就用text的，如果没有，就用我们默认的https://
    const replacedText = text.includes('http://') || text.includes('https://') ? text : 'https://' + text
    // 将转换后的数据赋值给 inputValue
    ruleForm.value.material[index].link = replacedText
}

/** 下一步预览广告 */
const handleNext = async () => {
    if (!ruleForm.value.fb_user_id) {
        ElMessage.error('请先授权Facebook用户')
        return
    }
    if (ruleForm.value.account_info.length === 0 || ruleForm.value.account_info[0].account_id === '') {
        ElMessage.error('请选择广告账户')
        return
    }
    if (!ruleForm.value.campaign_name) {
        ruleFormRef.value.validateField('campaign_name', (error) => {
            if (error) ruleFormRef.value.scrollToField('campaign_name')
        })
        return
    }
    if (!ruleForm.value.adsets_name) {
        ruleFormRef.value.validateField('adsets_name', (error) => {
            if (error) ruleFormRef.value.scrollToField('adsets_name')
        })
        return
    }
    if (!ruleForm.value.daily_budget) {
        ruleFormRef.value.validateField('daily_budget', (error) => {
            if (error) ruleFormRef.value.scrollToField('daily_budget')
        })
        return
    }
    if (JSON.stringify(ruleForm.value.region) === JSON.stringify([])) {
        ruleFormRef.value.validateField('region', (error) => {
            if (error) ruleFormRef.value.scrollToField('region')
        })
        return
    }
    if (JSON.stringify(ruleForm.value.audience_interest_words) === JSON.stringify([])) {
        ruleFormRef.value.validateField('audience_interest_words', (error) => {
            if (error) ruleFormRef.value.scrollToField('audience_interest_words')
        })
        return
    }
    if (ruleForm.value.material.length === 0) {
        ElMessage.error('请上传素材')
        return
    }
    for (let item of ruleForm.value.material) {
        if (item.loading === true) {
            return ElMessage.error(`有素材正在上传，请稍后`)
        }
    }
    await ruleFormRef.value.validate((valid, fields) => {
        if (!valid) return
        // 校验通过，生成预览广告
        if (ruleForm.value.batch_type === 1) {
            previewData.value = [
                {
                    id: 1,
                    label: ruleForm.value.adsets_name,
                    children: [],
                },
            ]
            ruleForm.value.material.forEach((item, index) => {
                previewData.value[0].children.push({
                    id: index + 2,
                    label: ruleForm.value.title,
                    type: item.type,
                    path: item.path1,
                })
            })
        } else {
            previewData.value = []
            ruleForm.value.material.forEach((item, index) => {
                previewData.value.push({
                    id: index + 1,
                    label: ruleForm.value.adsets_name,
                    children: [
                        {
                            id: item.id,
                            label: ruleForm.value.title,
                            type: item.type,
                            path: item.path1
                        }
                    ]
                })
            })
        }
        currentStep.value++
        api.template.handleEventTracking({ type: 13, source: localStorage.getItem('TemplateSource') })
    })
}

/** 创建广告 */
const handleCreate = async () => {
    await previewRuleFormRef.value.validate((valid, fields) => {
        if (!valid) return
        const material_data = ruleForm.value.material.map((item) => {
            return {
                path: item.path,
                link: item.link,
            }
        })
        const account_data = ruleForm.value.account_info.map((item) => {
            return {
                account_id: item.account_id,
                name: item.name,
                page_id: item.page,
                pixel_id: item.pixel,
            }
        })
        emit('submit', 'loading')
        api.template.batchCreateFBAd({
            type: ruleForm.value.batch_type,
            user_id: ruleForm.value.fb_user_id,
            account_info: JSON.stringify(account_data),
            t_id: props.id,
            campaign_name: ruleForm.value.campaign_name,
            adsets_name: ruleForm.value.adsets_name,
            daily_budget: parseFloat(ruleForm.value.daily_budget),
            material_data: JSON.stringify(material_data),
            area: JSON.stringify(ruleForm.value.region),
            age_min: ruleForm.value.age_min + '',
            age_max: ruleForm.value.age_max + '',
            sex: ruleForm.value.sex,
            interests: JSON.stringify(ruleForm.value.audience_interest_words),
            rule_ids: ruleForm.value.rule_switch ? ruleForm.value.rule : '',
            link: ruleForm.value.link_switch ? ruleForm.value.link : '',
            title: ruleForm.value.title,
            description: ruleForm.value.description,
            message: ruleForm.value.message,
            call_type: ruleForm.value.call_type,
            num: ruleForm.value.batch_num,
        }).then(res => {
            console.log(res);
            isDrawerShow.value = false
            emit('submit', 'wait')
        }).catch(err => {
            console.log(err);
            isDrawerShow.value = false
            if (err.msg) {
                emit('submit', err.msg)
            } else {
                emit('submit', '网络错误')
            }
        })
    })
}

onMounted(async () => {
    await checkAuth()
    getTemplateDetail()
    getAllAudience()
})

watch(
    [
        () => ruleForm.value.campaign_name,
        () => ruleForm.value.adsets_name,
        () => ruleForm.value.daily_budget,
        () => ruleForm.value.sex,
        () => ruleForm.value.age_min,
        () => ruleForm.value.age_max,
        () => ruleForm.value.rule_switch,
        () => ruleForm.value.rule,
        () => ruleForm.value.title,
        () => ruleForm.value.description,
        () => ruleForm.value.message,
        () => ruleForm.value.call_type,
        () => ruleForm.value.batch_type,
        () => ruleForm.value.link_switch,
        () => ruleForm.value.link,
    ],
    (now, old) => {
        if (JSON.stringify(now) !== JSON.stringify(old) && (old[0] || old[4] || old[5])) {
            handleSaveDetail()
        }
    },
    { deep: true }
)

watch(() => ruleForm.value.account_info, (now, old) => {
    if (JSON.stringify(now) !== JSON.stringify(old) && JSON.stringify(now) !== JSON.stringify([]) && JSON.stringify(old) !== JSON.stringify([]) && ruleForm.value.c_id !== 6) {
        handleSaveDetail()
    }
}, { deep: true })

watch(() => ruleForm.value.region, (now, old) => {
    if (JSON.stringify(now) !== JSON.stringify(old) && JSON.stringify(now) !== JSON.stringify([]) && JSON.stringify(old) !== JSON.stringify([])) {
        handleSaveDetail()
    }
}, { deep: true })

watch(() => ruleForm.value.audience_interest_words, (now, old) => {
    if (JSON.stringify(now) !== JSON.stringify(old) && JSON.stringify(now) !== JSON.stringify([]) && JSON.stringify(old) !== JSON.stringify([])) {
        handleSaveDetail()
    }
}, { deep: true })

watch(
    () => ruleForm.value.material,
    (now, old) => {
        if (
            JSON.stringify(now) !==
            JSON.stringify([])
        ) {
            handleSaveDetail()
        }
    },
    { deep: true }
)
</script>

<style lang="less">
.template-detail-drawer {
    .el-drawer__header {
        height: 64px;
        padding: 0;
        margin: 0;
        display: block;
    }

    .el-drawer__body {
        padding: 0;
        margin: 0;
    }
}

.template-detail-video-preview {
    max-height: 50vh;
    margin-top: 0;
    margin-bottom: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0;

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        padding: 0;
        height: 100%;
        max-height: 50vh;
    }

    video {
        width: 100%;
        height: 100%;
        max-height: 50vh;
        object-fit: contain;
    }
}

.ad-template-rule-select {
    .add-rule {
        height: 34px;
        padding: 0 20px;
        color: #2b82ff;
        font-size: 14px;
        cursor: pointer;
        line-height: 34px;

        i {
            margin-right: 8px;
        }
    }

    .el-select-dropdown__item {
        height: 34px;
        display: flex;
        padding: 0 20px 0 46px;
        flex-direction: column;

        &::after {
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            content: "";
            width: 16px;
            height: 16px;
            border: 1px solid #d9d9d9;
            border-radius: 50%;
            -webkit-font-smoothing: antialiased;
        }

        &.selected {
            font-weight: 400;
            background: #F5F7FA !important;
            border-radius: 0 !important;
        }

        &.selected::after {
            content: '' !important;
            background: #2B82FF;
            box-shadow: inset 0 0 0 3px #ffffff;
            border: 1px solid #2b82ff;
        }
    }
}
</style>

<style lang="less" scoped>
.top-container {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px;
    background: #2b82ff;

    span {
        font-size: 16px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
    }

    img {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}

.detail-container {
    padding: 32px 0 16px 0;

    .detail-steps {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 32px;

        .active-step {
            font-size: 16px;
            font-family: PingFang SC-Medium;
            font-weight: 500;
            color: #262626;
            line-height: 24px;
        }

        span {
            font-size: 16px;
            font-family: PingFang SC-Regular;
            font-weight: 400;
            color: #595959;
            line-height: 24px;
            margin-left: 8px;
        }

        div {
            width: 120px;
            height: 0px;
            opacity: 1;
            border: 1px solid #f0f0f0;
            margin: 0px 40px;
        }
    }

    .detail-form {
        height: calc(100vh - 224px);
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0 20px 0 24px;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: #d9d9d9;
            border-radius: 8px;
        }

        :deep(.el-input-group__append, .el-input-group__prepend) {
            padding: 0 12px;
            font-size: 14px;
            font-family: PingFang SC-Regular;
            font-weight: 400;
            color: #262626;
        }

        :deep(.el-form-item__label) {
            width: 150px;
            box-sizing: content-box;
            padding-right: 32px;
            font-family: PingFang SC-Regular;
            color: #262626 !important;
        }

        :deep(.el-form-item) {
            margin-bottom: 16px;

            .el-form-item__error {
                padding-top: 0;
            }
        }

        :deep(.login-button.el-button) {
            border: 1px solid #2b82ff;
            font-size: 14px;
            font-family: PingFang SC-Medium;
            font-weight: 500;
            color: #2b82ff;
        }

        :deep(.unlogin-button.el-button) {
            font-size: 14px;
            font-family: PingFang SC-Medium;
            font-weight: 500;
        }

        :deep(.el-input__inner) {
            color: #262626;
        }

        :deep(.el-radio-button__inner) {
            background-color: #ffffff !important;
            color: #262626;
        }

        :deep(.el-icon-close) {
            top: -1px;
            right: -5px;
        }

        :deep(.el-tag--mini) {
            height: 22px;
            line-height: 22px;
            padding: 0 8px;
        }

        :deep(.el-select__tags-text) {
            font-size: 14px;
            color: #262626;
        }

        .divider {
            position: relative;
            box-sizing: border-box;
            width: 100%;
            height: 34px;
            background: #f4f6f8;
            border-radius: 2px;
            font-size: 14px;
            font-family: PingFang SC-Medium;
            font-weight: 500;
            color: #262626;
            line-height: 34px;
            padding: 0 20px 0 28px;
            margin-bottom: 24px;
            display: flex;
            justify-content: space-between;

            span {
                cursor: pointer;
                color: #2b82ff;
            }

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 16px;
                transform: translateY(-50%);
                display: inline-block;
                width: 3px;
                height: 14px;
                background: #2b82ff;
                border-radius: 4px;
            }
        }

        .age-divider {
            width: 14px;
            height: 1px;
            background-color: #595959;
            margin: 2px 8px;
            display: inline-block;
        }

        .login-user {
            display: flex;
            align-items: center;
            height: 100%;
            gap: 16px;

            img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
            }

            span {
                line-height: 16px;
                vertical-align: middle;
            }
        }

        :deep(.el-table) {
            width: unset;
            margin-top: 6px;
            margin-left: 90px;
            margin-bottom: 24px;
            border: 1px solid #E4E7ED;
            border-bottom: none;

            .el-table__body tr:hover>td {
                background-color: transparent !important;
            }

            tr {
                height: 40px;
            }

            .el-table__cell {
                padding: 0;
            }

            .el-table__header-wrapper .cell {
                font-family: PingFang SC-Medium;
                font-weight: 500;
                color: #8c8c8c;
            }

            .cell {
                font-family: PingFang SC-Regular;
                font-size: 12px;
                font-weight: 400;
                color: #595959;
            }
        }

        .rule-form-item {
            :deep(.el-form-item__label) {
                line-height: normal;
            }

            .rule-description {
                width: 100%;
                height: 118px;
                background: #FFFFFF;
                border-radius: 4px;
                border: 1px solid #DCDFE6;
                overflow-y: auto;
                line-height: normal;
                padding: 8px 0;

                &::-webkit-scrollbar {
                    width: 2px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #d9d9d9;
                    border-radius: 8px;
                }

                .rule-description-item {
                    display: flex;
                    margin: 0 8px 12px 8px;

                    .name {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        background: #F4F4F5;
                        border-radius: 4px;
                        border: 1px solid #E9E9EB;
                        padding: 0 8px;
                        height: 24px;
                        line-height: 24px;

                        span {
                            font-weight: 500;
                            color: #262626;
                            font-family: PingFang SC-Regular;
                            font-size: 14px;
                            text-align: justified;
                        }

                        i {
                            color: #8c8c8c;
                            cursor: pointer;
                        }
                    }

                    .content {
                        padding-left: 8px;
                        margin-top: 2px;
                        line-height: 1.5;
                    }
                }

                .tip {
                    font-family: PingFang SC-Regular;
                    font-weight: 400;
                    font-size: 14px;
                    color: #c0c4cc;
                    margin-left: 14px;
                }
            }
        }

        .help {
            font-size: 14px;
            font-family: PingFang SC-Regular;
            text-decoration: none;
            font-weight: 400;
            color: #2b82ff;
            margin-left: 16px;
            cursor: pointer;
        }

        .recommend {
            font-size: 12px;
            color: #ffb900;
            margin-left: 16px;
            margin-bottom: 8px;
        }

        :deep(.add-material-button.el-button) {
            border: 1px solid #2b82ff;
            font-size: 14px;
            font-family: PingFang SC-Medium;
            font-weight: 500;
            color: #2b82ff;
            padding: 6px 32px;

            i {
                font-size: 20px;
                margin-right: 8px;
                vertical-align: middle;
            }

            span {
                vertical-align: middle;
            }

            &:hover {
                background: #2b82ff;
                color: #ffffff;
            }
        }

        .material-container {
            margin-left: 182px;
            margin-bottom: 24px;
            min-height: 204px;
            border: 1px solid #2B82FF;
            border-radius: 4px;
            background-color: #F8F8FA;
            padding: 20px;
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            .material {
                position: relative;
                box-sizing: border-box;
                width: 162px;
                height: 162px;
                border-radius: 2px;
                border: 1px solid #DCDFE6;

                .type-icon {
                    position: absolute;
                    top: 8px;
                    left: 8px;
                    color: #2B82FF;
                    font-size: 24px;
                }

                &>img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                &>video {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                &:hover .mask {
                    display: flex;
                }

                .mask {
                    display: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.4);
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 16px;

                    i {
                        font-size: 16px;
                        color: #ffffff;
                        position: absolute;
                        top: 8px;
                        right: 8px;
                        cursor: pointer;
                    }

                    :deep(.el-button) {
                        margin-left: 0;
                    }
                }
            }

            :deep(.el-input) {
                width: 162px;
                margin-top: 4px;
            }
        }

        .preview-container {
            margin-bottom: 24px;

            .account-id {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                span {
                    margin-right: 20px;
                }
            }

            :deep(.el-form-item) {
                margin-bottom: 6px;
            }


            .preview-table {
                padding-left: 58px;

                .header {
                    width: 100%;
                    height: 46px;
                    background: #f7f8fa;
                    display: flex;
                    margin-top: 16px;

                    div {
                        position: relative;
                        flex: 1;
                        padding-left: 40px;
                        font-size: 14px;
                        font-family: PingFang SC-Medium;
                        font-weight: 600;
                        color: #262626;
                        line-height: 46px;
                    }

                    &>div:nth-child(1)::after,
                    &>div:nth-child(2)::after {
                        content: '';
                        position: absolute;
                        top: 25%;
                        right: 0;
                        height: 22px;
                        width: 0px;
                        border: 1px solid #e8e9eb;
                    }
                }

                .content {
                    display: flex;
                    align-items: center;
                    min-height: 100px;
                    box-sizing: border-box;
                    border-bottom: 1px solid #80868a;

                    .content-tree {
                        max-height: 100%;
                    }

                    span {
                        font-size: 14px;
                        font-family: PingFang SC-Regular;
                        font-weight: 400;
                        color: #262626;
                    }

                    &>div {
                        flex: 1;
                    }

                    &>div:not(:nth-child(1)) {
                        span {
                            padding-left: 40px;
                        }
                    }

                    :deep(.el-tree-node__expand-icon) {
                        color: #4f4f4f;
                        font-size: 14px;
                    }

                    :deep(.el-tree-node__expand-icon.is-leaf) {
                        color: transparent !important;
                    }

                    :deep(.el-tree-node__content) {
                        height: unset;
                        background: #ffffff !important;
                    }

                    :deep(.el-tree-node) {
                        margin-top: 8px;
                        margin-bottom: 8px;
                    }
                }

                .footer {
                    display: flex;
                    width: 100%;
                    height: 46px;
                    background: #f7f8fa;
                    border: 1px solid #e8e9eb;

                    &>div {
                        flex: 1;
                        font-size: 14px;
                        font-family: PingFang SC-Regular;
                        font-weight: 400;
                        color: #262626;
                        line-height: 46px;
                        padding-left: 40px;
                    }
                }
            }

            .rule-description {
                width: 100%;
                height: 118px;
                background: #FFFFFF;
                border-radius: 4px;
                border: 1px solid #DCDFE6;
                overflow-y: auto;
                line-height: normal;
                padding: 8px 0;

                &::-webkit-scrollbar {
                    width: 2px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #d9d9d9;
                    border-radius: 8px;
                }

                .rule-description-item {
                    display: flex;
                    margin: 0 8px 12px 12px;

                    span {
                        font-weight: 500;
                        color: #262626;
                        font-family: PingFang SC-Regular;
                        font-size: 14px;
                        text-align: justified;
                    }

                    .content {
                        font-weight: 400;
                        color: #595959;
                        margin-top: 2px;
                        line-height: 1.5;
                    }
                }
            }
        }

        .create-tip {
            margin-left: 58px;
            font-size: 14px;
            font-family: PingFang SC-Regular;
            font-weight: 400;
            color: #262626;
        }
    }

    .detail-footer {
        box-sizing: border-box;
        position: fixed;
        bottom: 0;
        right: 0;
        width: 800px;
        height: 64px;
        background: #ffffff;
        box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.08);
        display: flex;
        padding: 16px 24px;
        justify-content: flex-end;

        :deep(.el-button) {
            margin-left: 20px;
            font-family: PingFang SC-Medium;
            font-size: 14px;
        }

        :deep(.el-button.is-disabled) {
            color: #606266;
            border-color: #c6e2ff;
        }
    }


    :deep(.el-radio.el-radio--small) {
        height: 100%;

        .el-radio__label {
            padding-left: 8px;
        }
    }

    :deep(.vxe-switch) {
        padding: 0;

        .vxe-switch--button:focus {
            box-shadow: none !important;
        }
    }

    :deep(.vxe-checkbox) {
        .vxe-checkbox--icon {
            box-shadow: none !important;
        }

        .vxe-checkbox--label {
            color: #262626;
            font-family: PingFangSC-Regular, PingFang SC;
            line-height: 17.5px;
        }
    }
}
</style>
